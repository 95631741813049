<template>
  <div class="mt-14 pl-8 pr-8">
    <div class="text-left">
      <h1 class="ocupation__title">Ocupación</h1>
    </div>
    <v-row>
      <v-col cols="8">
        <p class="text-title-dark-blue mt-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="irCreate()"
                v-bind="attrs"
                v-on="on"
                x-small
                color="#466be3"
                fab
                outlined
                dark
              >
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span> Editar mapa </span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                @click="getData()"
                v-bind="attrs"
                v-on="on"
                x-small
                color="#466be3"
                fab
                dark
              >
                <v-icon> mdi-refresh </v-icon>
              </v-btn>
            </template>
            <span> Actualizar ocupación del mapa </span>
          </v-tooltip>
        </p>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="search"
          label="Buscar colaborador por cédula"
          outlined
          rounded
          dense
          append-outer-icon="mdi-magnify"
          @click:append-outer="getInfoCC"
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="#16255b"
                  style="margin-top: 4px"
                  v-on="on"
                  fab
                  dark
                  x-small
                  @click="getInfoCC()"
                >
                  <v-icon> mdi-magnify </v-icon>
                </v-btn>
              </template>
              Buscar
            </v-tooltip>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <div>
      <p class="text-title-xl">Conozca los estados de puesto</p>
      <v-row>
        <v-col cols="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="status-box status-box-total" v-bind="attrs" v-on="on">
                <small>Total</small>
                <p class="status-box-p">{{ total }}</p>
              </div>
            </template>
            <span> Total de puestos registrados en el mapa </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="status-box status-box-ocu" v-bind="attrs" v-on="on">
                <small>Ocupados</small>
                <p class="status-box-p">{{ totalOccupation }}</p>
              </div>
            </template>
            <span> Total de puestos ocupados por los colaboradores </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="status-box status-box-free" v-bind="attrs" v-on="on">
                <small>Libres</small>
                <p class="status-box-p">{{ totalFree }}</p>
              </div>
            </template>
            <span> Total de puestos libres</span>
          </v-tooltip>
        </v-col>
        <v-col cols="2">
          <v-select
            background-color="#466be3"
            dark
            v-model="site"
            :items="listSite"
            label="Sede"
            item-text="name"
            item-value="name"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            background-color="#466be3"
            dark
            v-model="floor"
            :items="listFloor"
            label="Piso"
            item-text="floorNumber"
            item-value="floorNumber"
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <div class="d-flex justify-center">
      <div class="mt-8" id="containment-wrapper">
        <template v-for="(data, i) in listPositions">
          <v-tooltip :key="data.id" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                @click="openM(data, i)"
                v-bind="attrs"
                v-on="on"
                :key="data.id"
                v-html="data.html"
                :class="data.statusId === 7 && 'hidden'"
              ></div>
            </template>
            <span> {{ data.positionNum }} </span>
          </v-tooltip>
        </template>
        <div class="new-pos"></div>
      </div>
    </div>

    <v-dialog width="450" v-model="modal.free">
      <v-card class="modal-o px-2 py-2">
        <v-layout justify-end>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon x-small>
                <v-icon
                  color="#466be3"
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="modal.free = false"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span> Cerrar mensaje </span>
          </v-tooltip>
        </v-layout>

        <v-card-title class="pt-1">
          <v-layout justify-center>
            <h3>{{ message.title }}</h3>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p>
            {{ message.text }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog width="600" v-model="modal.info">
      <v-card class="modal-o px-2 py-2">
        <v-layout justify-end>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon x-small>
                <v-icon
                  color="#466be3"
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="modal.info = false"
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </template>
            <span> Cerrar información </span>
          </v-tooltip>
        </v-layout>
        <v-card-title class="pt-0">
          <!-- Imagen del colaborador -->
          <v-progress-circular
            v-if="loading.img"
            :size="50"
            color="#466be3"
            indeterminate
          ></v-progress-circular>
          <img
            v-else
            class="img-profile-o"
            height="70"
            width="70"
            :src="imageFlag === true ? img : img.img"
          />
          <!-- --------------------- -->
          <label class="name-o pl-3" v-if="this.collaborator != null">
            {{ this.collaborator.name }} {{ this.collaborator.lastname }}
          </label>
        </v-card-title>
        <v-card-text>
          <!-- Información del colaborador -->

          <v-progress-circular
            v-if="loading.collaborator"
            :size="80"
            color="#466be3"
            indeterminate
          ></v-progress-circular>

          <template v-else>
            <v-row class="mt-1">
              <v-col class="pb-0" cols="12">
                <p class="text-label-dark-o">Tipo de cargo</p>
                <p class="text-label-o" v-if="this.collaborator != null">
                  {{ this.collaborator.profile_type }}
                </p>
              </v-col>

              <v-col class="pb-0" cols="12">
                <p class="text-label-dark-o">Cargo</p>
                <p
                  class="text-label-1-o"
                  style="padding-bottom: 0px"
                  v-if="
                    this.collaborator != null &&
                    this.collaborator.profile != null
                  "
                >
                  {{ this.collaborator.profile.name }}
                </p>
              </v-col>
              <v-col class="pb-0" cols="12">
                <p class="text-label-dark-o">Campaña</p>
                <p class="text-label-o" v-if="this.collaborator != null">
                  {{ this.collaborator.campaign }}
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="pb-0" cols="6">
                <p class="text-label-dark-o"># Documento</p>
                <p class="text-label-o">
                  {{ this.infoPersona.colaborador }}
                </p>
              </v-col>
              <v-col class="pb-0" cols="6">
                <p class="text-label-dark-o">Correo</p>
                <p class="text-label-o" v-if="this.collaborator != null">
                  {{ this.collaborator.email }}
                </p>
              </v-col>
            </v-row>
          </template>

          <v-divider class="mb-5 mt-5"></v-divider>

          <!-- Información de ocupación -->

          <v-row>
            <v-col class="pb-0" cols="6">
              <p class="text-label-dark-o">Centro de costos</p>
              <p class="text-label-o">{{ this.infoPersona.cc }}</p>
            </v-col>
            <v-col class="pb-0" cols="6">
              <p class="text-label-dark-o">Subcentro de costos</p>
              <p class="text-label-o">{{ this.infoPersona.scc }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-0" cols="6">
              <p class="text-label-dark-o">Nombre del Host</p>
              <p class="text-label-o">{{ this.infoPersona.hostname }}</p>
            </v-col>
            <v-col class="pb-0" cols="6">
              <p class="text-label-dark-o">Dirección MAC</p>
              <p class="text-label-o">{{ this.infoPersona.mac_addres }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-0" cols="6">
              <p class="text-label-dark-o">CPU</p>
              <p class="text-label-o">{{ this.infoPersona.cpu }}</p>
            </v-col>
            <v-col class="pb-0" cols="6">
              <p class="text-label-dark-o">Monitor</p>
              <p class="text-label-o">{{ this.infoPersona.monitor }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-0" cols="6">
              <p class="text-label-dark-o">Ubicación</p>
              <p class="text-label-o">{{ this.infoPersona.ubicacion }}</p>
            </v-col>
            <v-col class="pb-0" cols="6">
              <p class="text-label-dark-o">Ubicación OCS</p>
              <p class="text-label-o">{{ this.infoPersona.ubicacion_ocs }}</p>
            </v-col>
          </v-row>

          <v-row class="pb-2">
            <v-col class="pb-0" cols="6">
              <p class="text-label-dark-o">Fecha</p>
              <p class="text-label-o">{{ this.infoPersona.cct_created }}</p>
            </v-col>
            <v-col class="pb-0" style="text-align: initial" cols="6">
              <p class="text-label-dark-o">Puesto</p>

              <v-chip small color="#466be3" label text-color="white">
                <strong> {{ this.infoPersona.puesto }} </strong>
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-overlay :value="loading.all">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>

    <div class="screen-size">
      <v-card style="border-radius: 15px">
        <v-card-title class="pt-6">
          <v-layout justify-center>
            <img
              class="ml-6"
              width="80"
              height="80"
              src="../../assets/icon/info.svg"
            />
          </v-layout>
          <v-btn
            @click="$router.go(-1)"
            color="#466BE3"
            icon
            x-small
            style="display: flex; left: 0px; bottom: 45px"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="login___txt_title">¡Oh no!</p>
          <p class="login___txt_sms pb-0 mb-0">
            Esta vista está únicamente disponible en pantallas de resoluciones
            superiores a 1200px
          </p>
        </v-card-text>

        <v-card-actions class="pb-8">
          <v-row>
            <v-col class="mb-0 pb-0" cols="12">
              <slot name="actions"></slot>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                @click="$router.go(-1)"
                class="alert__button"
              >
                Regresar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import Api from "@/utils/api";
export default {
  data() {
    return {
      listFloor: [],
      listSite: [],
      site: null,
      floor: null,
      info: [],
      listPositions: [],
      listFree: [],
      modal: {
        info: false,
        free: false,
      },
      message: {
        title: "",
        text: "",
      },
      infoPersona: {},
      totalFree: 0,
      totalOccupation: 0,
      total: 0,
      collaborator: {},
      search: "",
      img: "https://duvapi.tars.dev/storage/images/IW5XYkbqmQWGLXE81N4kqjfHnfHoWeKxST5A8tfU.png",
      loading: {
        all: false,
        img: false,
        collaborator: false,
      },
      floorId: null,
      employee: null,
      blueprint: null,
      imageFlag: false,
    };
  },
  watch: {
    site: function () {
      // if (newo && this.floor) {
      //   this.getData();
      //   console.log(this.site);
      // }
      let headquarter = this.listSite.filter(
        (element) => this.site === element.name
      );
      Api.Auth()
        .getFloorByHeadqueater(headquarter[0].id)
        .then((res) => (this.listFloor = res.data.data));
    },
    floor: function (newo) {
      if (newo && this.site) {
        this.getData();
      }
    },
    search: function () {
      if (this.employee !== null && this.employee !== this.search) {
        let data = document.querySelector(".search-o");
        data.classList.remove("search-o");
        data.classList.add("txt-pos");
        this.employee = null;
      }
    },
  },
  methods: {
    getBlueprint() {
      Api.Auth()
        .getFloorById(this.floorId)
        .then((res) => (this.blueprint = res.data.data.blueprint.image))
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading.all = false;
          let containment = document.getElementById("containment-wrapper");
          containment.style.backgroundImage = `url('${this.blueprint}')`;
        });
    },
    getHeadquarters() {
      Api.Auth()
        .getAllHeadqueaters()
        .then((res) => (this.listSite = res.data.data));
    },
    irCreate() {
      this.$router.push({
        name: "ocupationCreate",
        params: { id: this.floorId },
      });
    },
    openM(position, i) {
      if (!position.positionNum.includes("-")) {
        var data = this.gatInfoPersona(position.positionNum);
        if (data) {
          this.modal.info = true;
          this.infoPersona = data;
          this.getCollaborator(this.infoPersona.colaborador);
          this.getImg(this.infoPersona.colaborador);
        } else {
          this.modal.free = true;
          this.message.title = "EL PUESTO ESTA DISPONIBLE";
          this.message.text =
            "El puesto " +
            position.positionNum +
            " esta disponible para ser ocupado por un colaborador";
          this.infoPersona = {};
        }
      }
    },
    getInfoCC() {
      var data = null;
      for (let i = 0; i < this.info.length; i++) {
        if (this.info[i].colaborador == this.search) {
          data = this.info[i];
        }
      }
      if (data != null) {
        this.employee = data.colaborador;
        document
          .getElementById("draggable" + data.puesto)
          .classList.remove("txt-pos", "txt-pos-dis");
        document
          .getElementById("draggable" + data.puesto)
          .classList.add("search-o");
      } else {
        this.modal.free = true;
        this.message.title = "USUARIO NO ENCONTRADO";
        this.message.text =
          "El colaborador con la cédula " +
          this.search +
          " no fue encontrado en la sede " +
          this.site +
          " y en el piso " +
          this.floor;
      }
    },
    gatInfoPersona(position) {
      var data = null;
      for (let i = 0; i < this.info.length; i++) {
        if (this.info[i].puesto == position + "") {
          data = this.info[i];
        }
      }
      return data;
    },
    getData() {
      this.loading.all = true;
      Api.noAuth()
        .getDataOcupation()
        .then((resp) => resp.json())
        .then((data) => {
          this.info = data;
          this.getSiteMap();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.all = false;
        });
    },
    getSiteMap() {
      if (this.floor) {
        this.loading.all = true;
        let data = this.listFloor.filter(
          (element) => element.floorNumber === this.floor
        );
        this.floorId = data[0].id;
        Api.Auth()
          .getSiteMap(data[0].id)
          .then((res) => {
            if (res.data.cod == 0) {
              this.listPositions = res.data.data;
              var free = 0;
              this.listFree = [];
              let countFree = 0;
              this.totalFree = countFree;
              let countOccupation = 0;
              this.totalOccupation = countOccupation;
              for (let i = 0; i < this.listPositions.length; i++) {
                var data = this.gatInfoPersona(
                  this.listPositions[i].positionNum
                );
                if (data === null && this.listPositions[i].statusId !== 7) {
                  countFree++;
                  this.totalFree = countFree;
                  // this.totalFree++;
                  this.listFree.push(this.listPositions[i]);
                  setTimeout(() => {
                    this.getChange();
                  }, 500);
                } else if (
                  data !== null &&
                  this.listPositions[i].statusId !== 7
                ) {
                  // this.totalOccupation++;
                  countOccupation++;
                  this.totalOccupation = countOccupation;
                }
                let countTotal = 0;
                this.listPositions.forEach((element) => {
                  if (element.statusId !== 7) {
                    countTotal++;
                  }
                });
                this.total = countTotal;
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.listPositions = [];
          })
          .finally(() => {
            // this.loading.all = false;
            this.getBlueprint();
          });
      }
    },
    getChange() {
      for (let i = 0; i < this.listFree.length; i++) {
        if (this.listFree[i].positionNum.includes("-")) {
          document
            .getElementById("draggable" + this.listFree[i].positionNum)
            .classList.remove("txt-pos");
          document
            .getElementById("draggable" + this.listFree[i].positionNum)
            .classList.add("txt-pos-wall");
        } else {
          document
            .getElementById("draggable" + this.listFree[i].positionNum)
            .classList.remove("txt-pos");
          document
            .getElementById("draggable" + this.listFree[i].positionNum)
            .classList.add("txt-pos-dis");
        }
      }
    },
    getCollaborator(cc) {
      this.collaborator = {};
      this.loading.collaborator = true;
      Api.noAuth()
        .profile(cc)
        .then((res) => {
          let element = res.data.data;
          element.name = this.toTitleCase(element.name);
          element.lastname = this.toTitleCase(element.lastname);
          element.profile.name = this.toTitleCase(element.profile.name);
          element.profile_type = this.toTitleCase(element.profile_type);
          element.campaign = this.toTitleCase(element.campaign);
          this.collaborator = element;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.collaborator = false;
        });
    },
    getImg(cc) {
      this.loading.img = true;
      Api.Auth()
        .getImg(cc)
        .then((res) => {
          if (
            !res.data.data ||
            (res.data.data &&
              res.data.data == "https://app.brm.co/imgs/imgPerfil.png")
          ) {
            this.img =
              "https://duvapi.tars.dev/storage/images/IW5XYkbqmQWGLXE81N4kqjfHnfHoWeKxST5A8tfU.png";
            this.imageFlag = true;
          } else {
            this.imageFlag = false;
            this.img = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading.img = false;
        });
    },
    toTitleCase(str) {
      if (str) {
        return str
          .toLowerCase()
          .split(" ")
          .map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(" ");
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.getData();
    this.getHeadquarters();
    //this.getSiteMap();
  },
};
</script>
<style scope>
@import "./../../assets/css/main.less";

.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__append-outer {
  margin-top: 0px !important;
}

/* .hidden div {
  visibility: hidden;
} */

.screen-size {
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #33333363;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.screen-size-text {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  color: #f2f4ff;
}

.size-s {
  width: 14px !important;
  height: 12px !important;
  border: 1px solid #466be3;
}

.size-m {
  width: 14px !important;
  height: 24px !important;
  border: 1px solid #466be3;
}

.size-l {
  width: 28px !important;
  height: 12px !important;
  border: 1px solid #466be3;
}

.size-l2 {
  width: 56px !important;
  height: 12px !important;
  border: 1px solid #466be3;
}

.size-xl {
  width: 28px !important;
  height: 24px !important;
  border: 1px solid #466be3;
}

.size-xl2 {
  width: 56px !important;
  height: 24px !important;
  border: 1px solid #466be3;
}

.size-xl3 {
  width: 28px !important;
  height: 56px !important;
  border: 1px solid #466be3;
}

.size-xl4 {
  width: 56px !important;
  height: 56px !important;
  border: 1px solid #466be3;
}

.size-wall-s {
  width: 14px !important;
  height: 12px !important;
  background-color: #000000;
}

.size-wall-m {
  width: 14px !important;
  height: 24px !important;
  background-color: #000000;
}

.size-wall-l {
  width: 28px !important;
  height: 12px !important;
  background-color: #000000;
}

.size-wall-l2 {
  width: 56px !important;
  height: 12px !important;
  background-color: #000000;
}

.size-wall-xl {
  width: 28px !important;
  height: 24px !important;
  background-color: #000000;
}

.size-wall-xl2 {
  width: 56px !important;
  height: 24px !important;
  background-color: #000000;
}

.size-wall-xl3 {
  width: 28px !important;
  height: 56px !important;
  background-color: #000000;
}

.size-wall-xl4 {
  width: 56px !important;
  height: 56px !important;
  background-color: #000000;
}

.text-label-dark-o {
  color: #2e3e75 !important;
  text-align: initial;
  font-size: 17px;
  margin-bottom: 5px !important;
  font-weight: 400;
}
.text-label-o {
  color: #898989 !important;
  text-align: initial;
  font-size: 17px;
  margin-bottom: 10px !important;
}

.text-label-1-o {
  color: #898989 !important;
  text-align: initial;
  font-size: 17px;
  margin-bottom: 0px !important;
}

.name-o {
  font-weight: bold;
  color: #466be3;
  font-size: 18px;
}

.search-o {
  color: #fff;
  background-color: #ff6a29;
  margin: 0px !important;
  padding-top: 3px !important;
  font-size: 5.5px !important;
  font-weight: bold !important;
  font-family: "RobotoRegular" !important;
  border: 1px solid #ff6a29;
  padding: 0px !important;
  padding-left: 0px !important;
  width: 18px;
  cursor: pointer;
}

.img-profile-o {
  border: 2px solid;
  border-radius: 104px;
  border-color: #466be3;
}

.status-box {
  margin: 0px;
  width: 75px;
  float: left;
  margin-right: 20px;
  height: 55px;
}

.status-box-p {
  margin-top: 0px;
  margin-bottom: 0px !important;
  font-size: 18px;
}

.status-box-total {
  background-color: #466be3;
  border: #466be3 1px solid;
  color: #fff;
}

.status-box-free {
  border: dashed #7b7b7b 1px;
  color: #7b7b7b;
}

.status-box-ocu {
  border: #466be3 1px solid;
  color: #7b7b7b;
}

.st-map {
  width: 1400px;
  height: 800px;
  border: 1px solid;
  border-color: #466be3;
  position: fixed;
}
#ball {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.img-1 {
  text-align: center !important;
  padding-top: 4px !important;
  margin-right: 4px !important;
  background-color: #e2e2e2 !important;
  border-radius: 6px !important;
  border: 1px solid !important;
  border-color: #a19f9f !important;
  width: 30px !important;
  height: 30px !important;
  float: left !important;
}

.txt-pos {
  margin: 0px !important;
  padding-top: 3px !important;
  font-size: 5.5px !important;
  font-weight: bold !important;
  font-family: "RobotoRegular" !important;
  border: 1px solid #466be3;
  padding: 0px !important;
  padding-left: 0px !important;
  width: 18px;
  cursor: pointer;
}

.txt-pos-dis {
  margin: 0px !important;
  padding-top: 3px !important;
  font-size: 5.5px !important;
  font-weight: bold !important;
  font-family: "RobotoRegular" !important;
  border: dashed #7b7b7b 1px !important;
  padding: 0px !important;
  padding-left: 0px !important;
  width: 18px;
  cursor: pointer;
}

.txt-pos-wall {
  margin: 0px !important;
  padding-top: 3px !important;
  font-size: 5.5px !important;
  font-weight: bold !important;
  font-family: "RobotoRegular" !important;
  padding: 0px !important;
  padding-left: 0px !important;
  width: 18px;
  cursor: auto !important;
}

.txt-pos:hover {
  background-color: #466be3;
  color: #fff;
}

.txt-pos-dis:hover {
  background-color: #ccc;
  /* color: #fff; */
}

.draggable {
  width: 18px;
  height: 14px;
  float: left;
  margin: 2px;
  text-align: center;
}

#containment-wrapper {
  /* width: 100%; */
  width: 1000px;
  /* height: 1010px; */
  height: 705px;
  border: 2px solid #ccc;
  /* padding: 5px; */
  background-size: 100%;
  background-position: bottom;
  margin-bottom: 8px;
}

.new-pos {
  /* margin-top: 15px; */
  width: 100%;
  height: 100px;
  border-bottom: 3px dashed #466be3;
  /* padding: 5px; */ /* Aqui no puede haber padding porque al momento de guardar y comsumir nuevamente la api, se van a mover los elementos */
}
/* .position {
  border: 1px red solid;
  padding: 3px;
} */

h3 {
  clear: left;
}

* {
  box-sizing: border-box;
  margin-top: 0px;
}
body,
html {
  font-family: "RobotoRegular";
  width: 100%;
  height: 100%;
}
body {
  padding: 0px;
  background-color: #333;
  color: #fff;
  margin: 0px;
  padding: 0px;
}
.magnify {
  z-index: 20;
  border: 3px solid #999;
  position: relative;
  width: 900px;
  height: 640px;
  margin: 0px auto;
  overflow: hidden;
}

.magnify img {
  display: block;
}

.element_to_magnify {
  position: relative;
  width: 900px;
  height: 640px;
}
.element_to_magnify a {
  display: block;
  position: absolute;
  top: 80px;
  right: 80px;
  background-color: rgba(200, 200, 200, 0.25);
  padding: 4px;
  font-size: 18px;
  color: #fff;
}
.element_to_magnify img {
  width: 100%;
  height: auto;
}

.magnify_glass {
  z-index: 300;
  position: absolute;
  border: 4px solid #ccc;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.modal-o {
  border: 2px solid #466be3 !important;
  border-radius: 14px !important;
}

.ocupation__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.login___txt_title {
  font-family: "AsapRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}
.login___txt_sms {
  font-family: "ProximaNovaRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #898989;
}

.alert__button:hover {
  background-color: #324ca1 !important;
}

@media (min-width: 1201px) {
  .screen-size {
    display: none;
  }
}

@media (max-width: 1023px) {
  .screen-size {
    top: 0;
  }
}
</style>
